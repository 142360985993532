import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import InfoBox from "../../components/InfoBox";
import QuoteBox from "../../components/QuoteBox";
import CtaPrimary from "../../components/CtaPrimary";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Accueil: "/",
  "Consultation en Analytics": "/fr/consultation-analytique",
  "Tutoriel GTM": "/fr/configuration-de-google-tag-manager"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-tag-manager-setup"
);


const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Ai-je besoin de Google Tag Manager?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Oui, parce que votre site Web souhaite très probablement exécuter Google Analytics et d'autres balises de script tiers. Configurer tout cela est beaucoup plus facile et rapide avec Google Tag Manager. De plus, votre site <a href='https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173'>se charge un peu plus rapidement</a> également."
        }
      },
      {
      "@type": "Question",
      "name": "Quand dois-je utiliser Google Tag Manager?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Déjà si vous ne souhaitez utiliser que Google Analytics, vous devez utiliser Google Tag Manager. Configurer le <a href='https://bluerivermountains.com/fr/suivi-des-événements'>suivi des événements</a> et d'autres configurations est beaucoup plus rapide et plus facile avec GTM. De plus, il existe de nombreux tutoriels et guides en ligne qui expliquent comment, par exemple, <a href='http://bluerivermountains.com/fr/configuration-de-google-analytics'>configurer Google Analytics</a>."
        }
      },
      {
        "@type": "Question",
        "name": "Comment utiliser Google Tag Manager avec Google Analytics?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ne mettez pas la balise Google Analytics dans le code source de votre site. Ajoutez uniquement la balise Google Tag Manager, puis implémentez et <a href='https://bluerivermountains.com/fr/configuration-de-google-analytics'>configurez Google Analytics</a> via Google Tag Manager. Toutes les configurations personnalisées comme le <a href='https://bluerivermountains.com/fr/suivi-des-événements'>suivi des événements</a> ou l'ajout d'autres balises de script, vous le faites dans GTM."
        }
     },
     {
       "@type": "Question",
       "name": "Quelle est la différence entre Google Analytics et Google Tag Manager?",
       "acceptedAnswer": {
       "@type": "Answer",
       "text": "Google Analytics est la bibliothèque qui collecte des données sur les visites de votre site Web. Google Tag Manager, en revanche, est une bibliothèque qui s'exécute sur votre site pour implémenter d'autres bibliothèques ou outils comme Google Analytics. Parce que bon nombre de ces outils ont des extraits de code JavaScript supplémentaires pour envoyer des données, vous les configurez tous dans GTM."
       }
    },
    {
      "@type": "Question",
      "name": "Où dois-je placer le code Google Tag Manager?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "La première partie du code doit être placée aussi haut que possible dans la section <head>. Je recommande de l'implémenter dans le <head>, mais après toutes les balises <style> ou <script> qui sont essentielles pour le rendu de la page, car nous ne voulons pas les retarder. La deuxième partie de l'extrait de code GTM permet une fonctionnalité de base sur les sites avec JavaScript désactivé. Elle doit être placée aussi haut que possible dans l'élément <body>. La logique derrière le positionnement des deux balises est d'assurer le chargement anticipé de GTM. Cela vous permet d'interférer et de contrôler des parties du processus de chargement de la page le plus tôt possible."
      }
   },
   {
     "@type": "Question",
     "name": "Google Tag Manager inclut-il Google Analytics?",
     "acceptedAnswer": {
     "@type": "Answer",
     "text": "Non, mais Google Tag Manager vous permet d'implémenter Google Analytics en quelques secondes avec seulement quelques clics. La seule chose dont vous avez besoin est votre identifiant de suivi Google Analytics. En général, cependant, vous n'avez pas besoin d'utiliser Google Analytics avec Google Tag Manager. Ils sont indépendants l'un de l'autre."
     }
  }
  ]
}
`;

const GTMsetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Tutoriel d'installation de Google Tag Manager: Guide de configuration ${currentYear}`}
        description="Découvrez comment utiliser GTM pour configurer Google Analytics, le suivi des événements, les balises de remarketing et une couche de données, ainsi que les meilleures pratiques pour le suivi des téléchargements et des liens externes."
        lang="fr"
        canonical="/fr/configuration-de-google-tag-manager"
        image="google-tag-manager-tutorial-hero.png"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="gtm-setup/google-tag-manager-tutorial-hero.png"
          alt="interface utilisateur de Google Tag Manager"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Tutoriel Google Tag Manager</H>

        <p>En tant que <Link to="/fr/consultant-google-tag-manager">consultant Google Tag Manager</Link>, j'ai configuré GTM sur <b>plus de 100 sites clients</b>. Ce tutoriel Google Tag Manager est là où je vous enseigne le processus que j'ai affiné au fil des ans, étape par étape, avec des exemples et des vidéos pour que vous puissiez apprendre.</p>
        <p>Plus bas, vous pouvez <Link to="/fr/configuration-de-google-tag-manager">télécharger un fichier de configuration GTM</Link> avec toutes les meilleures pratiques suivantes à importer dans votre conteneur.</p>
        <p>Si vous ne pouvez pas attendre, plongez directement dans le <Link to="/fr/configuration-de-google-tag-manager">tutoriel d'installation</Link> ou apprenez <Link to="/fr/configuration-de-google-tag-manager">comment configurer Google Tag Manager</Link>. Mais si vous êtes un <b>débutant</b>, il est important de comprendre d'abord <em>comment</em> utiliser un <Link to="/fr/gestion-des-tags">système de gestion de balises</Link> avec d'autres outils.</p>
        <p>Alors continuez à lire ci-dessous en premier.</p>
        <H as="h2">Comment utiliser Google Tag Manager?</H>
        <p>Je suppose que vous savez déjà <Link to="/fr/qu-est-ce-que-google-tag-manager">ce qu'est Google Tag Manager</Link>. Parlons donc de son fonctionnement et de son utilisation.</p>
        <p>Idéalement, vous ne voulez avoir qu'une <b>seule</b> balise tierce dans le code source de votre site Web ou application web.</p>
        <QuoteBox
          quote={`La seule balise tierce sur votre site devrait être l'extrait de code Google Tag Manager.`}
        />
        <p>Toutes les autres balises sont ensuite implémentées via le gestionnaire de balises lui-même. Les autres balises de marketing et de suivi sont par exemple Google Analytics (GA), Facebook, Twitter, LinkedIn, AdWords, DoubleClick et dieu sait quoi d'autre.</p>
        <p>La principale raison en est les <Link to="/fr/avantages-de-google-tag-manager">avantages de Google Tag Manager</Link> :</p>
        <ul>
          <li>implémentation <b>plus facile et rapide</b> des balises marketing</li>
          <li>évolutivité sur chaque page et à travers plusieurs domaines</li>
          <li><b>déclencheurs intégrés</b> pour les soumissions de formulaires, le suivi des défilements et le suivi des vidéos</li>
          <li>gestion des utilisateurs avec plusieurs comptes GTM</li>
          <li>une <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">vitesse de chargement du site un peu plus rapide</a></li>
        </ul><br />
        <p>En raison de ces avantages, déjà <a target="_blank" href="https://w3techs.com/technologies/overview/tag_manager">30% de tous les sites web sur internet utilisent un gestionnaire de balises</a>. Et parmi eux, Google Tag Manager détient une part de marché de <a target="_blank" rel="noopener" href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet">94%</a>.</p>
        <p>Donc, à moins que vous n'ayez une raison solide de ne pas ajouter une balise à GTM, en règle générale, <b>ajoutez toutes les balises au conteneur GTM</b>.</p>

        <QuoteBox
          quote={`Utilisez GTM comme une couche de connexion entre votre site web et les balises tierces.`}
        />
        <p>Utilisez GTM comme une <b>couche intermédiaire</b> entre votre site et les services tiers. Sans cela, votre site et les balises tierces ne sont pas en connexion directe. Ces services sont principalement des bibliothèques JavaScript pour les outils de marketing ou de suivi qui sont implémentés avec une balise. Mais toutes les autres balises s'appliquent également.</p>
        <p>La seule exception à la règle s'applique lorsque vous faites de l'optimisation de conversion avec des outils de test A/B.</p>
        <p>Car pendant l'optimisation du taux de conversion, les tests A/B vont charger différentes variantes d'une page. Ainsi, le visiteur peut voir comment le contenu est re-rendu pendant une fraction de seconde.</p>
        <p>Pour éviter le scintillement du CSS et garantir que les tests de variantes se chargent rapidement, une balise de test A/B peut également aller directement dans le code source.</p>
        <p>Maintenant que nous avons clarifié cela, regardons la mise en œuvre.</p>
        <H as="h2">Installer Google Tag Manager sur votre site web</H>
        <p>Commençons le tutoriel Google Tag Manager en vous montrant où obtenir l'extrait de code Google Tag Manager, puis où l'installer sur le site web. Vous pouvez vous connecter en utilisant simplement votre compte Google habituel.</p>
        <ol>
          <li><H as="h3">Créer un compte Google Tag Manager</H>
            Pour installer GTM, vous devez d'abord aller sur le <a rel="noopener" target="_blank"
              href="https://tagmanager.google.com/">site officiel</a> et créer un nouveau compte Google Tag Manager.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-create-account.png"
              alt="Création de compte Google Tag Manager"
              caption="Tout d'abord, créez un compte Google Tag Manager, choisissez un nom de conteneur, comme le nom de votre site web, puis obtenez l'extrait de code."
              className="article-img"
            />
          </li>
          <li><H as="h3">Créer une propriété web</H>
            Sélectionnez la propriété <b>Web</b> pour obtenir un code pour un site web ou une application web.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
              alt="Nom du conteneur GTM et sélection de la propriété web"
              className="article-img"
              caption="Il existe plusieurs types de conteneurs disponibles dans un compte GTM. Pour les sites web, choisissez web. Notez qu'il existe d'autres types de conteneurs pour les pages AMP, iOS et Android aussi."
            />
          </li>
          <li><H as="h3">Implémenter le code Google Tag Manager</H><ul>
            Ensuite, vous verrez le code Google Tag Manager à implémenter sur votre site web.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-code-snippet.png"
              alt="Extrait de code Google Tag Manager"
              caption="Ceci est la balise de conteneur Google Tag Manager. Elle comporte deux parties. Les instructions sur la manière d'implémenter les balises de script sont écrites au-dessus de chaque partie."
              className="article-img"
            />
            <li>Prenez la <b>première partie</b> de la <Link to="/fr/wiki-analytics/container-balise">balise conteneur</Link> et placez-la aussi haut que possible dans la balise <b>head</b> sur chaque page de votre site. Cela garantit que vous pouvez déclencher des balises tôt pendant le chargement des pages.</li>
            <li>La <b>deuxième partie</b> est un iframe à exécuter dans les navigateurs avec JavaScript désactivé. Installez la balise aussi haut que possible dans la balise <b>body</b> sur chaque page de votre site.<br />
              <ImgScreenshot
                src="data-layer/positionierung-data-layer-gtm.png"
                alt="le dataLayer est positionné avant la balise TMS dans le code source"
                caption={`La première balise dans le <head> est une couche de données. Ne vous inquiétez pas si vous ne savez pas encore ce que c'est (première flèche). Ensuite vient la première partie de la balise GTM (deuxième flèche). Enfin, l'autre partie de la balise GTM est implémentée en haut de l'élément <body>. Un code JavaScript peut être implémenté entre les deux, mais une couche de données est toujours implémentée avant la balise GTM et la balise <noscript> GTM est toujours la dernière.`}
                className="article-img"
              />
            </li>
          </ul>
          </li>

        </ol>
        <p>C'est la méthode courante pour implémenter GTM.</p>
        <p>Utilisez-vous un système de gestion de contenu populaire ? Si oui, vous pouvez également utiliser un <b>plugin</b> qui s'occupe de l'installation de Google Tag Manager.</p>
        <p>Cela dit :</p>
        <InfoBox
          type="info"
          headline="Si votre CMS vous propose également un plugin pour installer d'autres balises"
          h="false"
          dots="true"
          tweetcopy="Si votre CMS vous propose également un plugin pour installer d'autres balises, n'utilisez pas un autre plugin pour installer Google Analytics, Facebook ou Google Ads. Utilisez plutôt GTM pour installer ces balises."
        >
          <p>N'utilisez pas un autre plugin pour installer Google Analytics, Facebook ou Google Ads.</p>
          <p>Utilisez plutôt <b>GTM pour installer ces balises</b>.</p><br />
          <ol>
            <li>Cela se traduira par une vitesse de chargement de page plus rapide</li>
            <li>Vous aurez plus d'options pour configurer la balise</li>
          </ol>
        </InfoBox>
        <p>L'interface utilisateur de GTM reçoit également régulièrement des mises à jour avec de nouvelles fonctionnalités, donc il est presque toujours préférable d'implémenter d'autres balises marketing directement avec elle plutôt qu'avec une autre intégration.</p><p>De plus, les gains en temps de chargement sont bons pour votre <Link to="/fr/wiki-analytics/taux-de-rebond">taux de rebond</Link> et aident le SEO.</p>
        <H as="h3">Utilisez un plugin pour implémenter GTM</H>
        <p>Voici une liste des systèmes de gestion de contenu les plus courants et leurs plugins pour installer Google Tag Manager.</p>
        <H as="h4">WordPress</H>
        <p>Il existe deux plugins WordPress pour implémenter GTM que j'utiliserais. <b>Premièrement</b>, il y a l'option classique appelée <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">Google Tag Manager
          pour WordPress</a>.<br />
          La <b>deuxième</b> option est <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/google-site-kit/">Site
            Kit by Google</a>. Il permet principalement d'ajouter un tableau de bord à votre backend WordPress affichant des informations de votre compte Google Analytics et des données de Google Search Console - ce qui est plutôt sympa. Et il vous permet également d'installer GTM.</p>
        <H as="h4">Shopify</H>
        <p>Pour Shopify, il existe un plugin <b>gratuit</b> pour l'installation de GTM, appelé de manière créative <em><a rel="noopener" target="_blank"
          href="https://apps.shopify.com/trafficguard?surface_detail=google+tag+manager&amp;surface_inter_position=1&amp;surface_intra_position=6&amp;surface_type=search">Google
          Tag Manager Installer</a></em>.</p>
        <H as="h4">Squarespace</H>
        <p>Pour Squarespace, il n'existe aucune extension ou plugin GTM. Mais vous pouvez ajouter la balise GTM manuellement en visitant <b>sidebar</b> &gt; <b>settings</b> &gt; <b>advanced</b> &gt; <b>code injection</b>.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-installation-in-squarespace.png"
          alt="Menu de navigation Squarespace pour l'injection de code"
          caption={`Dans Squarespace, vous pouvez implémenter GTM sous Paramètres > Avancé > Injection de code`}
          className="article-img"
        />

        <p>Ensuite, vous collez la balise GTM dans les champs de formulaire comme ceci :</p>


        <ImgScreenshot
          src="gtm-setup/gtm-code-injection-in-squarespace.png"
          alt="Injecter des extraits de code GTM dans Squarespace"
          caption={`Mettez la première partie du code GTM dans le champ d'en-tête. La deuxième partie va dans le champ de pied de page.`}
          className="article-img"
        />

        <H as="h4">Wix</H>
        <p>Visitez le menu principal de votre site Wix dans la barre latérale gauche. De là, allez à <b>Marketing &amp; SEO</b> puis cliquez sur <b>Intégrations Marketing</b> plus bas dans la barre latérale.<br />
          Vous trouverez alors plusieurs intégrations Wix pour Google Analytics, le pixel Facebook et également une extension Wix pour implémenter Google Tag Manager.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-setup-in-wix.png"
          alt="Intégration Marketing Wix pour Google Tag Manager"
          caption={`Dans Wix, utilisez l'intégration marketing pour Google Tag Manager.`}
          className="article-img"
        />
        <p>Cliquez sur connecter et installez Google Tag Manager.</p>

        <H as="h2">Comment vérifier si GTM fonctionne ?</H>
        <InfoBox
          type="info"
          headline="Lorsque vous vous connectez pour la première fois à GTM"
          h="false"
          dots="true"
          tweetcopy="Lorsque vous vous connectez pour la première fois à GTM... Allez au bouton de soumission et publiez un conteneur vide. Sinon, une fois que vous testez si GTM fonctionne, le script renverra une erreur de réponse 400 et vous passerez des heures à déboguer pourquoi. 😭"
        >
          <p>Allez au bouton de soumission et publiez un <b>conteneur vide</b>.</p>
          <p>Sinon, une fois que vous testez si GTM fonctionne, le script renverra une <b>erreur de réponse 400</b> et vous passerez des heures à déboguer pourquoi. 😭 <br /><br />C'est classique 😉</p>
        </InfoBox>
        <p>Après avoir implémenté le script GTM et <b>publié une version de conteneur</b> (important), vous pouvez vérifier si Google Tag Manager fonctionne en effectuant l'une des vérifications suivantes :</p>
        <ol>
          <li><H as="h3">Mode aperçu et débogage</H>Connectez-vous à votre compte GTM et cliquez sur <b>aperçu</b>. Ensuite, ouvrez un nouvel onglet dans le navigateur et visitez votre site Web. La fenêtre de débogage GTM devrait s'ouvrir en bas de la fenêtre si GTM fonctionne correctement.<br />
            <ImgScreenshot
              src="event-tracking/google-tag-manager-event-trigger-preview.png"
              alt="Activer le mode débogage GTM"
              caption={`Activez le mode débogage GTM pour vérifier si GTM fonctionne correctement.`}
              className="article-img"
            />

          </li>
          <li><H as="h3">Outils de développement Chrome</H><b>Ouvrez les outils de développement Chrome</b> avec un clic droit sur n'importe quelle page de votre site et sélectionnez <em>inspecter</em> (ou F12 sur Windows et Shift+CTRL+J sur Mac).<br />
            Ensuite, allez à l'onglet <b>réseau</b> et <b>rechargez simultanément la page web</b> (F5 sur Windows et CMD+Shift+R sur Mac). L'onglet réseau se remplira de toutes les requêtes réseau nécessaires pour charger la page.<br />
            Dans le champ de filtre en haut à gauche, tapez <em>gtm.js</em> pour trouver la requête pour votre code JavaScript et vérifiez qu'elle a un <b>code de statut 200</b>.<br /><br />
            Laissez-moi vous montrer :<br /><br />
            <video

              loading="lazy"
              title={`Vérifiez si Google Tag Manager fonctionne`}
              autoplay
              muted
              playsinline
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/check-if-gtm-is-working.mp4" type="video/mp4" />
            </video>
            <br />
            <b>Si vous n'avez pas de code de statut 200, peut-être avez-vous oublié de soumettre et de publier un conteneur en premier dans GTM ?</b></li>
          <li><H as="h3">Google Tag Assistant</H>Installez l'extension Chrome <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/tag-assistant-by-google/kejbdjndbnbjgmefkgdddjlbokphdefk">Google Tag Assistant</a> et lancez-la sur votre site. Elle devrait indiquer un ID de conteneur GTM.<br />

            <ImgContainerFixed width="452px">
              <ImgScreenshot
                src="gtm-setup/gtm-validation-with-tag-inspector.png"
                alt="Google Tag Assistant valide que GTM se charge correctement"
                caption={`Vous pouvez également utiliser l'extension Chrome Google Tag Assistant pour vous assurer que Google Tag Manager fonctionne correctement.`}
                className="article-img"
              />
            </ImgContainerFixed>
          </li>
        </ol>
        <H as="h2">Comment configurer Google Tag Manager ?</H>
        <p>Lors de la configuration de Google Tag Manager, vous pouvez effectuer de nombreuses configurations avancées. Donc <b><em>comment</em></b> vous configurez GTM dépend de ce que vous prévoyez d'utiliser dans votre <Link to="/fr/gestion-des-tags">système de gestion de balises</Link>.</p>
        <p>C'est pourquoi j'ai réuni tous les tutoriels pertinents qui couvrent tout ce que vous pourriez vouloir configurer dans votre compte GTM - avec des exemples. Suivez simplement ce guide Google Tag Manager et créez ainsi une base solide pour la gestion des balises de votre site.</p>
        <p>Seul le tutoriel sur l'implémentation d'une couche de données nécessite des compétences en codage ou potentiellement des développeurs web.</p>
        <p><b>Remarque</b> : Dans ce tutoriel Google Tag Manager, nous utiliserons GTM en configurant <b>manuellement</b> de nouvelles balises et déclencheurs pour chaque événement. L'approche n'est pas super évolutive, mais elle est suffisamment rapide et facile, tout en répondant à la plupart des ambitions de suivi et en restant applicable à d'autres configurations avancées.</p>
        <p>Les grands sites web et les boutiques en ligne nécessitent une <b>solution de gestion des balises évolutive</b>. Par conséquent, une <Link to="/fr/couche-de-données">couche de données</Link> est implémentée comme élément central pour suivre les événements. Avec une telle configuration, vous pouvez utiliser des gestionnaires d'événements au lieu de configurer des balises, des déclencheurs et des variables pour chaque événement.</p>
        <ol>
          <li>
            <H as="h3">Configurer le suivi Google Analytics</H>
            <p>C'est la première étape pour tout le monde. Apprenez dans ce guide comment implémenter un suivi Google Analytics solide, avec des objectifs, des entonnoirs et vos propres visites exclues du trafic. Plus de meilleures pratiques.</p>

            <Link to="/fr/configuration-de-google-analytics"><CtaPrimary color="purple" arrow="true">Configurer Google Analytics</CtaPrimary></Link>

          </li>
          <li>
            <H as="h3">Configurer le suivi des événements</H>
            <p>Une fois que la mise en œuvre du suivi fondamental fonctionne comme il se doit, vous voudrez également apprendre à suivre l'engagement des utilisateurs. Combien de fois, par exemple, un visiteur envoie des soumissions de formulaires et clique sur un bouton de soumission ou un autre élément HTML ? Mon guide de <Link to="/fr/suivi-des-événements">suivi des événements</Link> explique exactement cela pour un <b>clic sur un bouton</b> et vous pouvez utiliser la même méthode pour tout autre suivi de clic.</p>
            <Link to="/fr/suivi-des-événements"><CtaPrimary color="purple" arrow="true">Configurer le suivi des événements</CtaPrimary></Link>
          </li>
          <li>
            <H as="h3">Ajouter des balises de remarketing</H>
            <p>Le cas d'utilisation le plus courant pour GTM <em>après</em> avoir installé GA est l'ajout de balises de remarketing à un site web. Après tout, elles constituent la majorité des balises marketing tierces et des codes de suivi qui encombrent la base de code de nos sites.<br />
              Nous les implémentons donc via notre compte GTM pour garder la base de code propre des balises marketing et analytiques tout en profitant des <Link to="/fr/avantages-de-google-tag-manager">avantages de Google Tag Manager</Link>.</p>
            <p>Apprenons à ajouter les balises de remarketing les plus courantes dans le domaine du marketing numérique, le <b>pixel Facebook</b> et la <b>balise de remarketing Google Ads</b>.</p>
            <H as="h4">Ajouter le pixel Facebook</H>
            <ul>
              <li>
                Tout d'abord, vous aurez besoin de votre <b>ID de pixel Facebook</b>. Visitez le <a rel="noopener" target="_blank" href="https://www.facebook.com/events_manager2/list/pixel/">Gestionnaire d'événements</a> de Facebook et cliquez sur le <b>signe plus vert</b> pour créer le pixel Facebook. Ensuite, votre ID de pixel sera affiché à l'écran.
                <ImgScreenshot
                  src="gtm-setup/get-fb-pixel.png"
                  alt="Le gestionnaire d'événements dans Facebook montre l'ID du pixel"
                  caption={`Trouvez votre ID de pixel Facebook dans le gestionnaire d'événements.`}
                  className="article-img"
                />
              </li>
              <li>
                Ensuite, via Google Tag Manager, <b>créez une nouvelle balise</b>, appelez-la par exemple <em>Facebook - Page view</em> et visitez la galerie de <b>modèles de balises</b>.
              </li>
              <li>
                Recherchez <em>Facebook</em> et sélectionnez le pixel Facebook.<br />

                <ImgScreenshot
                  src="gtm-setup/fb-pixel-template-gallery-gtm.png"
                  alt="La galerie de modèles de GTM montre la balise du pixel Facebook"
                  caption={`Implémentez le pixel Facebook à partir des modèles de balises de GTM.`}
                  className="article-img"
                />
              </li>
              <li>
                Ajoutez votre <b>ID de pixel Facebook</b> et cliquez sur <b>enregistrer</b>.
              </li>
              <li>
                Configurez la balise pour qu'elle se déclenche sur <b>toutes les pages</b>.
                <br /><br />
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installer le pixel Facebook dans Google Tag Manager`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/add-facebook-pixel-with-gtm.mp4" type="video/mp4" />
                </video>
              </li>
              <li>
                Ensuite, cliquez sur <b>soumettre</b> en haut à droite pour mettre la balise en ligne.
              </li>
            </ul>
            <H as="h4">Ajouter une balise de remarketing Google Ads</H>
            <ul>
              <li><b>Premièrement</b>, obtenez votre <b>ID de conversion Google Ads</b> pour votre audience depuis <b>Bibliothèque partagée &gt; Audiences</b>. L'interface utilisateur a récemment changé, mais cherchez les <b>détails de la balise</b> ou la <b>configuration de la balise</b> pour trouver les informations ci-dessous.
                <br />
                <ImgContainerFixed width="432px">
                  <ImgScreenshot
                    src="gtm-setup/google-ads-conversion-id.png"
                    alt="Extraits de code pour l'ID de conversion Google et le label de conversion"
                    caption={`Prenez votre ID de conversion et votre label de conversion depuis les détails de la balise dans votre audience.`}
                    className="article-img"
                  />
                </ImgContainerFixed>
              </li>
              <li>Ensuite, dans GTM, allez à la section <b>balises</b> et cliquez sur <b>nouveau</b> pour ajouter notre nouvelle balise marketing.</li>
              <li>Donnez-lui un nom comme <em>Google Ads - Page view</em>.</li>
              <li>Choisissez comme type de variable <b>Google Ads Remarketing</b>.</li>
              <li>Définissez votre <b>ID de conversion</b> et éventuellement le <b>label de conversion</b>. Ensuite, laissez la balise se déclencher sur <b>toutes les pages</b> et cliquez sur <b>enregistrer</b>.<br /><br />Laissez-moi vous montrer dans cette vidéo :
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installer la page de vue Google Ads avec GTM`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/google-ads-page-view.mp4" type="video/mp4" />
                </video>
              </li>
              <li>Cliquez sur <b>soumettre</b> en haut à droite pour mettre la balise marketing en ligne.</li>
            </ul>
            {/* Link -&gt; comment configurer la conversion google tag manager<br /> */}
            {/* [//]: # (### Implémenter le suivi des formulaires)<br /> */}
            {/* [//]: # (Link -&gt; suivi des formulaires) */}
            {/* implémenter le suivi du défilement */}
            {/* implémenter le suivi des vidéos */}
          </li>
          <li>
            <H as="h3">Implémenter une couche de données</H>
            <p>Vous voudrez implémenter une couche de données si vous configurez des balises régulièrement et que cela prend trop de temps et est tout simplement trop laborieux.</p>
            <p>Un autre avantage est que vous pouvez utiliser les informations de votre base de données pour déclencher des balises ou les envoyer en tant que données d'événement. D'autres sources de données externes peuvent également être intégrées. Les sites Web qui nécessitent le suivi des transactions e-commerce entrent généralement dans cette catégorie.</p>
            <p>Mon article sur la couche de données explique l'implémentation, les variables de la couche de données et comment configurer le suivi personnalisé de manière évolutive, ce qui est un cas d'utilisation typique pour les <b>grandes boutiques en ligne</b> qui ont besoin d'un suivi e-commerce amélioré.
            </p>

            <Link to="/fr/couche-de-données"><CtaPrimary color="purple" arrow="true">Implémenter une couche de données</CtaPrimary></Link>
          </li>
        </ol>
        <H as="h2">Meilleures pratiques pour chaque configuration GTM</H>
        <p>Chaque fois que je configure Google Tag Manager, la configuration comprend quelques configurations que j'ajoute à chaque fois. Ces
          meilleures pratiques sont applicables et utiles pour la plupart des entreprises et ne devraient pas manquer dans aucun tutoriel GTM. Consultez la liste ci-dessous et choisissez celles qui vous sont utiles.</p>
        <p>Plus bas, vous pouvez <Link to="/fr/configuration-de-google-tag-manager">télécharger une configuration GTM</Link> avec toutes ces meilleures pratiques à importer dans votre propre conteneur.</p>
        <H as="h3">Suivre les liens externes</H>
        <p>Suivre les clics sur les liens externes signifie suivre tous les clics sur des liens externes qui mènent de votre site Web à d'autres sites Web.
          Suivre les liens externes est une meilleure pratique qui vous permet de savoir vers quels sites Web vous envoyez des visites et vous aide à vérifier l'intérêt de vos visiteurs.<br />Pour implémenter le suivi des liens externes, il y a <b>trois étapes</b> :</p>
        <ol>
          <li>
            <H as="h4">Créer une variable d'événement personnalisée dans GTM</H>
            <ul>
              <li>Visitez <b>variables définies par l'utilisateur</b> depuis l'aperçu GTM et cliquez sur <b>nouveau &gt; variable d'événement automatique</b>.</li>
              <li>Elle est censée évaluer l'URL du lien d'un élément cliqué et renvoyer <code>true</code> si l'attribut <code>href</code> contient un lien externe - ou <code>false</code> si le lien est interne.</li>
              <li>Donnez à la nouvelle variable un nom comme <em>Linkisoutbound</em> et sélectionnez le <em>type de variable</em> pour être <b>URL de l'élément</b> et le <em>type de composant</em> <b>est externe</b>. Ensuite, cliquez sur <b>enregistrer</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/auto-event-variable-for-outbound-links.png"
                  alt="Paramètres pour la variable d'événement automatique des liens externes"
                  caption="La variable d'événement automatique dans Google Tag Manager va contenir une valeur de TRUE lorsque l'adresse du lien cliqué est externe et pointe vers une page externe."
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Créer un nouveau déclencheur pour les liens externes</H>
            <ul>
              <li>Dans la navigation latérale sous déclencheurs, cliquez sur nouveau et créez un nouveau déclencheur dans GTM.</li>
              <li>Sélectionnez le <em>type de déclencheur</em> pour être <b>juste des liens</b> et nommez-le par exemple <em>Événement - Lien externe</em>.</li>
              <li>Définissez le déclencheur pour <em>se déclencher sur certains clics de lien</em> et sélectionnez votre nouvelle variable d'événement automatique <b>Linkisoutbound</b> pour qu'elle soit égale à <code>true</code> :<br />

                <ImgScreenshot
                  src="gtm-setup/outbound-links-trigger-config.png"
                  alt="Configuration du déclencheur pour le suivi des liens externes"
                  caption={`Paramètres du déclencheur pour le suivi des liens externes.`}
                  className="article-img"
                />

              </li>

            </ul>
          </li>
          <li>
            <H as="h4">Créer une balise pour l'événement GA</H>
            <ul>
              <li>Visitez <b>variables</b> et cliquez sur <b>configurer</b> pour les <em>variables intégrées</em>. Ensuite, activez la variable <b>URL de clic</b>. Fermez à nouveau et allez à la section des balises.</li>
              <li>Créez une nouvelle balise <b>Google Analytics : Universal Analytics</b> nommée <em>Événement - Lien externe</em> avec le <em>type de suivi</em> défini sur <b>événement</b>.</li>
              <li>Pour la <b>catégorie</b> ajoutez simplement <code>Clic sur lien externe</code>, <b>action</b> est <code>clic</code> et <b>label</b> doit faire référence à notre variable GTM nouvellement activée. Pour ce faire, nous utilisons des doubles accolades <code>{`{{ Click URL }}`}</code>.</li>
              <li>La <b>valeur</b> de l'événement peut être définie sur <code>0</code>, sauf si vous pouvez attribuer une valeur nominale à un lien externe (par exemple, pour les liens d'affiliation).</li>
              <li>Le paramètre <b>coup non-interaction</b> reste défini sur <code>false</code>, car un clic externe est en effet une interaction utilisateur.</li>
              <li>La <b>variable de paramètres Google Analytics</b> doit être la même variable définie par l'utilisateur que dans votre balise pour les pages vues.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-external-link-clicks.png"
                  alt="Paramètres pour la balise d'événement Google Analytics pour suivre les liens externes"
                  caption={`Paramètres pour la balise d'événement pour suivre les liens externes.`}
                  className="article-img"
                />

              </li>

              <li>À la dernière étape, faites défiler vers le bas jusqu'à la section <em>déclenchement</em> et <b>séléctionnez le déclencheur nouvellement créé</b> <em>Événement - Lien externe</em>. Ensuite, cliquez sur <b>enregistrer</b> et <b>soumettre</b> vos modifications de conteneur depuis l'aperçu principal.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Suivre les clics sur les adresses email</H>
        <p>Les clics sur les emails sont une métrique utile qui a tendance à corréler avec les appels téléphoniques ou les visites en boutique.<br />Pour configurer le suivi des clics sur les emails dans Google Analytics, suivez les étapes du tutoriel ci-dessous :</p>
        <ol>
          <li>
            <H as="h4">Ajouter un nouveau déclencheur pour les clics sur les adresses email</H>
            <ul>
              <li>Activez la variable intégrée <b>URL de clic</b>. Vous pouvez passer cette étape si vous l'avez déjà fait dans la configuration précédente. Sinon : Allez dans la section <b>variables</b> et <b>configurez</b> les <em>variables intégrées</em> pour ajouter la variable <b>URL de clic</b>. Ensuite, fermez le panneau et allez dans la section des déclencheurs.</li>
              <li>Sous <b>déclencheurs</b>, cliquez sur <em>nouveau</em> et créez un nouveau déclencheur nommé par exemple <em>Événement - Clic email</em>, définissez le type sur <b>clic - juste des liens</b> et laissez-le se déclencher uniquement sur <em>certains clics de lien</em>.</li>
              <li>Dans la condition pour ces clics de lien, définissez les menus déroulants sur <b>URL de clic</b>, puis <b>contient</b> et ajoutez simplement la chaîne <code>mailto:</code><br /></li>

              <ImgScreenshot
                src="gtm-setup/email-click-trigger-settings-in-gtm.png"
                alt="Configuration du déclencheur pour suivre les clics sur les adresses email"
                caption={`Configuration du déclencheur pour suivre les clics sur les adresses email.`}
                className="article-img"
              />

            </ul>
          </li>
          <li>
            <H as="h4">Configurer la balise d'événement GA</H>
            <ul>
              <li>Créez une nouvelle balise, sélectionnez le type de balise <b>Google Analytics : Universal Analytics</b> et nommez-la <em>Événement - Lien email</em>. Ensuite, sélectionnez <b>événement</b> comme <em>type de suivi</em>.</li>
              <li>La <b>catégorie</b> peut être définie sur une chaîne statique <code>Clic sur lien email</code>, <b>action</b> est <code>clic</code> et <b>label</b> doit faire référence à <code>{`{{ Click URL }}`}</code>.</li>
              <li>La <b>valeur</b> de l'événement peut être à nouveau <code>0</code>, sauf si vous pouvez attribuer une valeur significative. Si, par exemple, vous savez que chaque 10ème contact par email conduit à une vente de 100$, vous pouvez alors attribuer 10$ comme valeur moyenne de l'événement.</li>
              <li>Le <b>coup non-interaction</b> doit afficher <code>false</code>, car l'interaction est volontaire et donc active.</li>
              <li>La variable des <b>Paramètres Google Analytics</b> doit être la même que pour votre balise de vue de page ou de lien externe.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-emails.png"
                  alt="Configuration de l'événement de clic sur email GA"
                  caption={`Paramètres de configuration de la balise d'événement GA pour les emails.`}
                  className="article-img"
                />

              </li>
              <li>La dernière étape consiste à aller jusqu'à <em>déclenchement</em> et à <b>séléctionner le déclencheur que nous venons de créer</b> <em>Événement - Clic email</em>. Ensuite, <b>enregistrez</b> et allez à l'aperçu pour <b>soumettre</b> vos modifications.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Suivre les clics sur les numéros de téléphone</H>
        <p>Le suivi des clics sur les numéros de téléphone est principalement utile sur les appareils mobiles. Cliquer sur un lien de numéro de téléphone initie directement un appel téléphonique. Sur les ordinateurs de bureau, les clics de souris ne déclenchent généralement rien. Mais, comme pour le suivi des clics sur les emails, c'est une bonne métrique pour confirmer les taux de contact globalement, car elle est corrélée avec d'autres méthodes de contact d'une entreprise.</p>
        <p>Apprenez à configurer GTM pour le suivi des clics sur les numéros de téléphone en suivant les étapes ci-dessous.</p>
        <ol>
          <li>
            <H as="h4">Créer un déclencheur pour les clics sur les numéros de téléphone</H>
            <ul>
              <li>Comme pour les autres guides ci-dessus, nous aurons besoin de la variable <b>URL de clic</b> activée à partir des <em>variables intégrées</em>.</li>
              <li>Ensuite, créez un nouveau déclencheur <em>Événement - Clic téléphone</em> du type <b>clic - juste des liens</b> qui se déclenche uniquement sur <em>certains clics de lien</em>.</li>
              <li>Remplissez la condition du déclencheur comme suit : <b>URL de clic</b> - <b>contient</b> puis la chaîne <code>tel:</code>. Ensuite, cliquez sur enregistrer.<br />

                <ImgScreenshot
                  src="gtm-setup/phone-number-trigger-in-gtm.png"
                  alt="Paramètres de GTM pour un déclencheur de numéro de téléphone"
                  caption={`Paramètres pour un déclencheur qui se déclenche sur les clics de numéro de téléphone.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Créer une balise d'événement de numéro de téléphone</H>
            <ul>
              <li>Ajoutez à nouveau une <b>balise Universal Analytics</b> de type <b>événement</b> et appelez-la <em>Événement - Clics téléphone</em></li>
              <li>La <b>catégorie</b> est la chaîne statique <code>Clic sur numéro de téléphone</code>, <b>action</b> est <code>clic</code> et comme <b>label</b> nous utilisons à nouveau la variable intégrée avec des doubles accolades, <code>{`{{ Click URL }}`}</code>.</li>
              <li>Si vous ne pouvez pas attribuer une valeur moyenne à un appel de prospect, laissez-la à <code>0</code>. Sinon, ajoutez un nombre pour la valeur moyenne.</li>
              <li>L'événement <b>coup non-interaction</b> doit être défini sur <code>false</code>, car il s'agit d'une interaction active et volontaire. Sélectionnez la <b>variable de paramètres</b> parmi vos variables définies par l'utilisateur, que vous utilisez également pour les vues de page.

                <ImgScreenshot
                  src="gtm-setup/ga-event-phone-number.png"
                  alt="Configuration de l'événement de clic sur numéro de téléphone GA"
                  caption={`Configuration de l'événement de clic sur numéro de téléphone GA.`}
                  className="article-img"
                />
              </li>
              <li>Connectez maintenant votre déclencheur nouvellement créé avec cette balise d'événement GA en descendant dans la zone de déclenchement et en sélectionnant le nouveau déclencheur <em>Événement - Clic téléphone</em>. La dernière étape consiste à <b>enregistrer</b> et à cliquer sur soumettre.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Suivre les téléchargements</H>
        <p>Suivre la fréquence à laquelle les visiteurs téléchargent vos documents est un bon indicateur d'engagement. Ces téléchargements peuvent être par exemple des eBooks, des feuilles Excel, des images, des vidéos ou de la musique.</p><p>Le suivi des téléchargements fonctionne bien pour distinguer les groupes de visiteurs qui n'étaient pas intéressés par le contenu de la page et les visiteurs qui étaient vraiment intéressés et ont téléchargé ce que vous avez offert.</p>
        <p>Suivez ce tutoriel pour apprendre à configurer le suivi des téléchargements :</p>
        <ol>
          <li>
            <H as="h4">Configurer un déclencheur GTM pour les clics de téléchargement</H>
            <ul>
              <li>Créez un nouveau déclencheur appelé <em>Événement - Téléchargements</em>, en tant que <em>type de déclencheur</em> choisissez <b>clic - juste des liens</b> qui ne se déclenche que sur <b>certains clics de lien</b>.</li>
              <li>Pour la condition, définissez la variable intégrée <b>URL de clic</b> sur <b>se termine par</b> la chaîne <code>.pdf</code> pour suivre par exemple les téléchargements de PDF. Modifiez la chaîne d'extension en fonction de l'extension de fichier que vous proposez en téléchargement.
              </li>
              <li>Si vous souhaitez suivre <b>plusieurs extensions de fichiers</b> pour le téléchargement, nous pouvons utiliser une expression régulière. Modifiez la condition en <b>URL de clic</b> puis <b>correspond à l'expression régulière (ignorer la casse)</b> et ajoutez cette chaîne RegEx <code>.pdf|.mp4|.mp3|.xlsx|.xls|.epub|.jpeg$</code>. N'hésitez pas à ajouter d'autres extensions de fichiers pertinentes pour votre site Web et à supprimer celles qui ne le sont pas. Terminez en cliquant sur <b>enregistrer</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/gtm-trigger-download-events.png"
                  alt="Configuration du déclencheur pour le suivi des téléchargements avec une variable intégrée"
                  caption={`Configuration du déclencheur pour le suivi des téléchargements avec une variable intégrée.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>

          <H as="h4">Suivre les téléchargements avec un événement Google Analytics</H>
          <ul>
            <li>Ajoutez une nouvelle balise de type Universal Analytics, sélectionnez <b>événement</b> comme type de suivi et nommez-la par exemple <em>Événement - Téléchargements</em>.
            </li>
            <li>La <b>catégorie</b> de l'événement est la chaîne <code>Téléchargement</code>, l'<b>action</b> est la chaîne <code>clic</code> et le <b>label</b> est <code>{`{{ Click URL }}`}</code>.</li>
            <li>Le <b>hit de non-interaction</b> est <code>false</code>, en raison de l'interaction étant un engagement actif.</li>
            <li>Et parmi les variables définies par l'utilisateur, utilisez la même <b>variable de paramètres</b> que dans les autres balises de suivi.<br />

              <ImgScreenshot
                src="gtm-setup/google-analytics-event-tag-downloads.png"
                alt="Balise d'événement pour le suivi des téléchargements"
                caption={`Paramètres de la balise d'événement pour le suivi des téléchargements.`}
                className="article-img"
              />

            </li>

            <li>Ajoutez maintenant un déclencheur à cette balise en faisant défiler vers le bas et sélectionnez <em>Événement - Téléchargements</em>.</li>
            <li>Étape suivante, cliquez sur <b>enregistrer</b>, allez à l'aperçu et <b>soumettez</b> les modifications.</li>
          </ul>
        </ol>
        {/* todo */}
        {/* <H as="h3">Ajouter le suivi du défilement</H> */}
        <H as="h2">Guide pour tester les balises et déclencheurs</H>
        <p>Un tutoriel Google Tag Manager ne serait pas complet sans une partie sur le débogage. Pour tester l'une des configurations d'événements précédentes et être sûr qu'elles fonctionnent, faites l'une des opérations suivantes :</p>
        <ol>
          <li><H as="h3">Mode aperçu GTM</H>Tout d'abord, lançons le mode aperçu et débogage. Dans l'aperçu de Google Tag Manager, cliquez sur le bouton <b>aperçu</b> en haut à droite. Ensuite, ouvrez un nouvel onglet <b>dans le même navigateur</b> et vous remarquerez qu'une fenêtre de débogage GTM s'ouvre en bas.<br /><br />
            En mode aperçu, exécutez vous-même les balises et les déclencheurs. Cliquez par exemple sur un lien externe ou un numéro de téléphone et voyez si le déclencheur et votre balise sont affichés dans la fenêtre de débogage GTM avec les détails de l'événement que vous avez ajoutés.<br />

            <ImgScreenshot
              src="event-tracking/google-tag-manager-debugging-mode.png"
              alt="débogueur google tag manager pour les événements"
              className="article-img"
              caption={`Le débogueur Google Tag Manager s'ouvre automatiquement une fois le mode aperçu activé et affiche des données sur les balises, les déclencheurs et les variables.`}
            />


          </li>
          <li><H as="h3">Rapport en temps réel de Google Analytics pour les événements</H>Une autre méthode consiste à vous connecter à Google Analytics puis à visiter le <b>rapport en temps réel pour les événements</b>. Les événements devraient apparaître quelques secondes après le déclenchement.

            <ImgScreenshot
              src="event-tracking/google-analytics-event-tracking-realtime-report.png"
              alt="rapport en temps réel de google analytics pour les événements"
              className="article-img"
              caption={`Vous pouvez vérifier les configurations GTM avec le rapport en temps réel de Google Analytics.`}
            />


          </li>
          <li><H as="h3">Extension Chrome pour le débogage</H>Installez une extension Chrome pour déboguer la balise et le déclencheur. Je recommande <a rel="noopener" target="_blank"
            href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh">Trackie</a> ou <a
              rel="noopener" target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl">Omnibug</a>, mais
            il existe aussi d'autres solutions. Une fois que vous avez ajouté l'extension à Chrome, vous pouvez ouvrir les <b>Outils de développement Chrome</b> et un nouvel onglet sera disponible. Il vous montre toutes les informations sur les règles de gestion des balises déclenchées. Si vous déclenchez ensuite vos déclencheurs en mode débogage, l'extension affichera les données des déclencheurs et des événements.
            <br />
            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-trackie.png"
              alt="L'extension Chrome Trackie affiche les données des événements."
              className="article-img"
              caption={`L'extension Chrome Trackie affiche les données des événements dans les outils de développement Chrome.`}
            />

            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-omnibug.png"
              alt="L'extension Chrome Omnibug affiche les données des événements pour le débogage"
              className="article-img"
              caption={`L'extension Chrome Omnibug affiche les données des événements pour le débogage dans les outils de développement Chrome.`}
            />
          </li>
        </ol>

        <H as="h2">Télécharger le fichier de configuration GTM</H>
        <p>Étant donné que les configurations ci-dessus sont universellement utiles à la plupart des implémentations de Google Tag Manager, j'ai créé la configuration GTM ci-dessus en tant que fichier à importer dans d'autres conteneurs Google Tag Manager.<br />
          C'est un fichier <code>.json</code> avec les paramètres et la convention de nommage que nous avons abordés. Vous pouvez simplement importer le code du conteneur sans avoir à tout configurer manuellement.</p>
        <p>Vous pouvez <b>l'utiliser avec un tout nouveau conteneur</b> pour gagner du temps dans la configuration des balises, ou vous pouvez <b>les importer dans votre conteneur existant</b> et mettre à jour la variable des paramètres Google Analytics, y compris l'ID de suivi avec votre propre ID.</p>
        <p>Vous pouvez télécharger et installer ces configurations (chacune avec des balises, déclencheurs et variables) pour configurer Google Tag Manager :</p>
        <ul>
          <li>Suivre les liens externes</li>
          <li>Suivre les clics sur les emails</li>
          <li>Suivre les clics sur les numéros de téléphone</li>
          <li>Suivre les téléchargements</li>
        </ul>
        <p className="baseline">Importez simplement les paramètres du conteneur et déployez-les. À des fins de démonstration, j'ai ajouté une variable de paramètres Google Analytics avec un ID de suivi Google Analytics de <em>UA-12345678-9</em>.</p> <p><b>Veuillez mettre à jour le code de suivi GA avec le vôtre</b> ou, alternativement, <b>modifiez la variable des paramètres GA dans la configuration de la balise avec la vôtre</b>.</p>
        <p>Téléchargez la configuration de l'installation GTM et voyez ci-dessous comment l'importer.</p>
        <a href="/downloads/best-practice-gtm-configs.json" download="best-practice-gtm-configs.json"><CtaPrimary color="purple">Télécharger la configuration GTM</CtaPrimary></a>
        <H as="h3">Importer les paramètres dans votre conteneur GTM</H>
        <p>Pour tirer le meilleur parti de ce tutoriel GTM, suivez les étapes ci-dessous et importez les paramètres dans votre conteneur GTM :</p>
        <ul>
          <li>allez dans <b>admin</b> &gt; <b>importer un conteneur</b>.</li>
          <li>séléctionnez le fichier JSON que vous venez de télécharger.</li>
          <li>choisissez un <b>nouvel espace de travail</b> nommé par exemple <em>Importer Bluerivermountains</em>.</li>
          <li>comme <b>option d'importation</b>, choisissez <b>fusionner</b> et <b>renommer</b> toutes les balises, déclencheurs et variables en conflit.</li>
          <li>cliquez sur <b>confirmer</b> puis sur le bouton <b>soumettre</b> et <b>publier</b> pour déployer les balises.<br /><br />Laissez-moi vous montrer dans cette vidéo :

            <video

              loading="lazy"
              title={`Vidéo tutoriel pour importer un conteneur Google Tag Manager`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/import-gtm-container.mp4" type="video/mp4" />
            </video>

          </li>

          <li>Enfin, changez l'ID de suivi GA dans la variable des paramètres Google Analytics avec votre propre ID de suivi ou mettez à jour la variable des paramètres dans les balises avec votre propre variable de paramètres.</li>
        </ul>
        <H as="h2">Remarque sur les données personnelles</H>
        <p>Nous devons être conscients des informations que nous suivons. Les données ne sont pas que des données, car les pays ont des réglementations sur la confidentialité des données qui affectent le type d'informations que nous pouvons collecter lors du suivi.</p>

        <p>De même, il existe également des conditions du côté de Google, qui interdisent de suivre des informations personnelles et d'envoyer les données à leurs serveurs.</p>

        <p>À ce sujet :</p>
        <p>Généralement, <b>les emails ou les numéros de téléphone sont des informations personnellement identifiables (PII)</b> et nous ne sommes pas autorisés à les envoyer à notre compte Google Analytics, car cela va à l'encontre de <a rel="noopener" target="_blank" href="https://support.google.com/analytics/answer/2795983">leurs conditions d'utilisation</a>.</p>
        <p>Cependant, les numéros de téléphone sur notre site web ou nos propres adresses email d'entreprise sont rarement privés, car il ne s'agit pas des données des utilisateurs mais des nôtres et elles sont publiquement disponibles sur le site web.<br />
          Néanmoins, si Google Analytics vérifiait <em>un jour</em> leur base de données et trouvait ces données, ils ne pourraient pas savoir que ce ne sont en fait pas des données PII.<br />
          Par conséquent, je recommande de ne prendre aucun risque et <b>d'obscurcir toutes les adresses email et numéros de téléphone envoyés au compte Google Analytics</b>.</p>
        <p>Simo Ahava a fait un excellent travail et a écrit un <a rel="noopener" target="_blank"
          href="https://www.simoahava.com/gtm-tips/remove-pii-google-analytics-hits/">script de tâche personnalisée pour supprimer les PII
          des hits Google Analytics</a> et je vous recommande de l'implémenter avec les configurations ci-dessus.<br />
          Ce n'est pas obligatoire, mais en l'implémentant, vous évitez toute confusion potentielle quant à savoir si vous détenez des données PII ou non.</p>
        <H as="h2">FAQ</H>
        <H as="h3">Ai-je besoin de Google Tag Manager ?</H>
        <p>Oui, car votre site web souhaite probablement exécuter Google Analytics et d'autres balises de script tiers. Configurer tout cela est beaucoup plus facile et rapide avec Google Tag Manager. De plus, votre site <a rel="noopener" target="_blank"
          href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">se charge
          un peu plus rapidement</a> également.</p>
        <H as="h3">Quand dois-je utiliser Google Tag Manager ?</H>
        <p>Déjà si vous ne souhaitez utiliser que Google Analytics, vous devez utiliser Google Tag Manager. Configurer le <Link to="/fr/suivi-des-événements">suivi des événements</Link>, le suivi inter-domaines ou le suivi des formulaires sont des étapes courantes suivantes, mais beaucoup plus <b>rapides</b> et <b>faciles</b> avec GTM qu'autrement. Il existe également des déclencheurs intégrés pour le suivi des défilements et le suivi des vidéos, ainsi que de nombreux tutoriels en ligne expliquant comment <Link to="/fr/configuration-de-google-analytics">configurer Google Analytics</Link> avec cela.</p>
        <H as="h3">Comment utiliser Google Tag Manager avec Google Analytics ?</H>
        <p>Connectez-vous à <a href="https://analytics.google.com" rel="noopener" target="_blank">analytics.google.com</a> avec votre compte Google et obtenez votre code de suivi Google Analytics, y compris l'ID de suivi. Maintenant, ne mettez pas la balise Google Analytics dans le code source de votre site. La seule balise codée en dur doit être la balise Google Tag Manager. Allez donc sur <a target="_blank" rel="noopener" href="https://tagmanager.google.com/">tagmanager.google.com</a> pour obtenir l'extrait de code GTM et implémentez-le plutôt sur chaque page de votre site web. Enfin, implémentez le code GA via une balise intégrée, ajoutez votre ID de suivi et continuez à <Link to="/fr/configuration-de-google-analytics">configurer Google Analytics</Link> <b>via Google Tag Manager</b>.
          Pour ajouter des balises marketing ou configurer des balises personnalisées, vous utiliserez toujours GTM à l'avenir.</p>
        <H as="h3">Quelle est la différence entre Google Analytics et Google Tag Manager ?</H>
        <p>Google Analytics est la bibliothèque pour collecter des données sur les visites et l'engagement sur votre site web. Google Tag Manager, en revanche, est une bibliothèque qui s'exécute sur votre site pour implémenter d'autres bibliothèques ou <em>outils de suivi</em> comme Google Analytics. Comme de nombreux outils de marketing et d'analyse ont des extraits de code JavaScript supplémentaires, vous utilisez l'interface utilisateur GTM pour les configurer tous.</p>
        <H as="h3">Où dois-je placer le code Google Tag Manager ?</H>
        <p>La <b>première</b> partie du code va <em>aussi haut que possible</em> dans la section <code>&lt;head&gt;</code>. Je recommande de l'implémenter dans la <code>&lt;head&gt;</code> mais après toutes les balises <code>&lt;style&gt;</code> ou <code>&lt;script&gt;</code> qui sont essentielles pour le rendu de la page - car nous ne voulons pas les retarder.<br />
          La <b>deuxième</b> partie permet d'activer une fonctionnalité de base dans les navigateurs avec JavaScript désactivé. Elle se place <em>aussi haut que possible</em> dans l'élément <code>&lt;body&gt;</code>.<br />
          La logique derrière le positionnement des deux balises est d'assurer le chargement anticipé de GTM. Cela vous permet de déclencher des balises personnalisées dès que possible pendant le chargement de la page.</p>

        <br />
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Code Google Tag Manager"
          className="article-img"
          caption={`La balise GTM a deux parties. La première est placée en haut de la balise head et l'autre juste après l'ouverture de la balise body (voir ci-dessous).`}
        />

        <br />

        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="positionnement du datalayer avant la balise de gestion des balises dans le code source"
          className="article-img"
          caption={`L'ordre général doit toujours être 1. objet de la couche de données 2. Google Tag Manager, les deux dans le <head> et 3. l'autre code GTM en haut de la balise <body>`}
        />

        <H as="h3">Google Tag Manager inclut-il Google Analytics ?</H>
        <p>Non, mais Google Tag Manager vous permet d'implémenter Google Analytics en quelques secondes avec seulement quelques clics. La seule
          chose dont vous avez besoin est votre <Link to="/fr/configuration-de-google-analytics">ID de suivi Google Analytics</Link>. En général, cependant, vous n'avez pas besoin d'utiliser Google Analytics avec Google Tag Manager.
          Ils sont <b>indépendants</b> l'un de l'autre.</p>
        <H as="h3">Comment accéder à Google Tag Manager ?</H>
        <p>Visitez <a target="_blank" href="https://tagmanager.google.com/" rel="noopener">tagmanager.google.com</a> et connectez-vous avec votre compte Google pour accéder à Google Tag Manager. Pour commencer à utiliser GTM, créez un nouveau compte et choisissez la propriété web comme plateforme cible. Ensuite, prenez l'extrait et installez-le sur chaque page de votre site web.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default GTMsetup;

